<!-- 新增账号 -->
<template>
    <div class="add_sys_account">
        <div class="content margin_left_60">

            <!-- 标题 -->
            <h3>新建普通管理员账号</h3>

            <el-card class="box margin_top_30">
                <div class="top">

                    <!-- 用户名 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span>用户名：</span>
                        </div>
                        <el-input clearable v-model="userName" placeholder="输入用户名"></el-input>
                    </div>

                    <!-- 手机号 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span>登录手机号：</span>
                        </div>
                        <el-input onkeyup="value=value.replace(/[^\d]/g,'')" clearable v-model="phone" placeholder="输入手机号"></el-input>
                    </div>

                    <!-- 密码 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span>密&nbsp;&nbsp;码：</span>
                        </div>
                        <el-input maxlength="20" clearable v-model="newPwd" placeholder="输入确认密码"></el-input>
                    </div>

                    <!-- 确认新密码 -->
                    <div class="com_dv margin_top_30">
                        <div class="min_dv">
                            <span>确认密码：</span>
                        </div>
                        <el-input maxlength="20" clearable v-model="confirmPwd" placeholder="输入确认密码"></el-input>
                    </div>
                </div>
                <div class="bom">
                    <el-button :loading="showLoading" :class="['commit_bt','bt_active']" @click.stop="doSaveAction">保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button>
                    <el-button :class="['commit_bt','bt_normal']" @click.stop="doCancelAction">取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button>
                </div>
            </el-card>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            //用户名
            userName: "",

            //电话
            phone: "",

            //新密码
            newPwd: "",

            //确定的新密码
            confirmPwd: "",

            //提交加载框
            showLoading: false,
        };
    },
    created() {},
    methods: {
        //保存的操作
        doSaveAction() {
            if (this.isEmpty(this.userName)) {
                this.showWarnMsg("请输入用户名");
                return;
            }

            if (this.isEmpty(this.phone)) {
                this.showWarnMsg("请输入手机号");
                return;
            }

            if (!this.testPhone(this.phone)) {
                this.showWarnMsg("手机号码格式不正确");
                return;
            }

            if (this.isEmpty(this.newPwd)) {
                this.showWarnMsg("请输入密码");
                return;
            }

            if (!this.testPwd(this.newPwd)) {
                this.showWarnMsg(
                    "密码格式错误,必须为6~20位数字、字母或者数字+字母的组合且不能包含特殊字符"
                );
                return;
            }

            if (this.isEmpty(this.confirmPwd)) {
                this.showWarnMsg("请输入确认密码");
                return;
            }

            if (this.confirmPwd != this.newPwd) {
                this.showWarnMsg("两次输入的密码不一致");
                return;
            }

            //加载
            this.showLoading = true;

            let param = this.getHttpParams();
            param.type = 2;
            param.name = this.userName;
            param.tel = this.phone;
            param.pw = this.newPwd;
            this.$http
                .fetchPost(this.$api.ADMINMANAGE, param)
                .then((res) => {
                    let result = res.data;
                    this.showLoading = false;
                    if (result.state == 200) {
                        //请求成功
                        this.showSuccessMsg("新建账户成功");
                        this.$router.go(-1);
                    } else {
                        this.showErrorMsg(`${result.msg},新建账户成功失败`);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    console.log("请求数据异常");
                });
        },

        //取消的操作
        doCancelAction() {
            //返回
            this.$router.go(-1);
        },
    },
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.add_sys_account {
    height: calc(100vh - 87px);
    display: flex;
    .content {
        margin-top: 40px;
        .box {
            padding: 20px 100px 20px 40px;
            .com_dv {
                display: flex;
                align-items: center;
                .min_dv {
                    text-align: right;
                    width: 120px;
                }
                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                    padding-left: 6px;
                }

                ::v-deep .el-input__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                ::v-deep .el-input {
                    width: 210px;
                }

                ::v-deep .el-input__suffix {
                    right: 0px;
                }
            }

            .bom {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 40px;

                .bt_normal {
                    background: #cfd1d4;
                    color: white;
                    margin-left: 50px;
                }

                .bt_active {
                    background: #409eff;
                    color: white;
                    cursor: pointer;
                }

                .commit_bt {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    width: 100px;
                    border: none;
                }
            }
        }
    }
}
</style>